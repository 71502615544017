import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { usePermissStore } from "../store/permiss";
import Home from "../views/home.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/index",
        name: "basetable",
        meta: {
          title: "index",
          permiss: "2",
        },
        component: () =>
          import(/* webpackChunkName: "table" */ "../views/Room.vue"),
      },
      {
        path: "/adminview",
        name: "adminview",
        meta: {
          title: "adminview",
          permiss: "3",
        },
        component: () =>
          import(/* webpackChunkName: "adminview" */ "../views/admin.vue"),
      },
      {
        path: "/overview",
        name: "overview",
        meta: {
          title: "overview",
          permiss: "overview",
        },
        component: () =>
          import(/* webpackChunkName: "overview" */ "../views/overview.vue"),
      },
      {
        path: "/ProjectManager",
        name: "ProjectManager",
        meta: {
          title: "Project Manager",
          permiss: "Project Manager",
        },
        component: () =>
          import(
            /* webpackChunkName: "ProjectManager" */ "../views/Project-Manager.vue"
          ),
      },
      {
        path: "/ProjectTeamMember",
        name: "ProjectTeamMember",
        meta: {
          title: "Project Team Member",
          permiss: "Project Team Member",
        },
        component: () =>
          import(
            /* webpackChunkName: "tabs" */ "../views/Project-Team-Member.vue"
          ),
      },
      {
        path: "/ITDept.",
        name: "ITDept.",
        meta: {
          title: "IT Dept.",
          permiss: "IT Dept.",
        },
        component: () =>
          import(/* webpackChunkName: "tabs" */ "../views/IT-Dept.vue"),
      },
      {
        path: "/ManufacturingDept.",
        name: "ManufacturingDept.",
        meta: {
          title: "Manufacturing  Dept.",
          permiss: "Manufacturing Dept.",
        },
        component: () =>
          import(
            /* webpackChunkName: "tabs" */ "../views/Manufacturing-Dept.vue"
          ),
      },
      // {
      //   path: "/President",
      //   name: "President",
      //   meta: {
      //     title: "President",
      //     permiss: "President",
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "tabs" */ "../views/President.vue"),
      // },
      // {
      //   path: "/FManager",
      //   name: "PresiFManagerdent",
      //   meta: {
      //     title: "F Manager",
      //     permiss: "F Manager",
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "tabs" */ "../views/F-Manager.vue"),
      // },
      // {
      //   path: "/permission",
      //   name: "permission",
      //   meta: {
      //     title: "权限管理",
      //     permiss: "13",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "permission" */ "../views/permission.vue"
      //     ),
      // },

      {
        path: "/Statistics",
        name: "Statistics",
        meta: {
          title: "Statistics",
          permiss: "Statistics",
        },
        component: () =>
          import(/* webpackChunkName: "export" */ "../views/Statistics.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "Login",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: "403 forbidden",
    },
    component: () => import(/* webpackChunkName: "403" */ "../views/403.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const loginMap = {
  0: "admin",
  1: "ProjectManager",
  2: "ProjectTeam Member",
  3: "ManufacturingDep",
  4: "ITDep",
  5: "overview",
  6: "FManager",
};

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Cash Flow Issue`;
  const role = sessionStorage.getItem("ms_usertype");
  const permiss = usePermissStore();
  if (!role && to.path !== "/login") {
    next("/login");
  } else if (
    role !== "admin" &&
    from.path === "/login" &&
    to.path === "/index"
  ) {
    if (role?.startsWith("Project Manager")) {
      next("/ProjectManager");
    } else {
      next("/" + role?.replaceAll(" ", ""));
    }
  } else if (to.meta.permiss && !permiss.key.includes(to.meta.permiss)) {
    // 如果没有权限，则进入403
    next("/403");
  } else {
    next();
  }
});

export default router;
