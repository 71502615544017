import axios from "axios";
import { ElLoading } from "element-plus";
import { ref } from "vue";
const fullscreenLoading = ref(false);
export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// 请求拦截器，设置token
apiClient.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 500);
    }

    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// apiClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       router.push("/login"); // 跳转到login页面
//     }
//     return Promise.reject(error);
//   }
// );
// export function setAuthorizationHeader(token: string) {
//   apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
// }
