<template>
  <el-config-provider :locale="en">
    <router-view />
    <el-dialog v-model="dialogVisible" width="50%">
      <div class="flex justify-center items-center">
        <img
          class="w-30 h-30"
          v-if="gameStatus === 'SUCCESS'"
          src="./assets/img/success.jpeg"
        />
        <img
          class="w-30 h-30"
          v-if="gameStatus === 'FAIL'"
          src="./assets/img/fail.png"
        />
      </div>
      <div class="flex justify-center">
        <div class="w-6/12 flex justify-between">
          <div class="w-[100px] flex items-center">
            <img class="w-8 h-8" src="./assets/img/taotalMoney.png" />
            ：{{ totalBudget.totalBudget }}
          </div>
          <div class="w-[100px] flex items-center">
            <img class="w-8 h-8" src="./assets/img/sat.png" /> ：
            {{ totalBudget.totalSat }}
          </div>
        </div>
      </div>
      <div class="flex gap-4 justify-center">
        <div class="h-10 flex items-center">
          <img class="w-6 h-6" src="./assets/img/contribution.png" />
        </div>
        <el-progress
          class="h-10 w-6/12"
          :text-inside="true"
          :stroke-width="20"
          :percentage="totalBudget.totalContribution"
          status="exception"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            Close
          </el-button>
        </span>
      </template>
    </el-dialog>
  </el-config-provider>
</template>

<script setup lang="ts">
import { ElConfigProvider } from "element-plus";
import en from "element-plus/es/locale/lang/en";
import { computed, provide, ref } from "vue";
import { apiClient } from "./utils/request";
const dialogVisible = ref(false);
const gameStatus = ref("");
const totalBudget = ref<any>({});

const roomNumber = ref(sessionStorage.getItem("roomNumber"));
const game = ref<any>({});

const getGameStatus = (totalBudgets) => {
  console.log("totalBudget", totalBudgets);
  totalBudget.value = totalBudgets;

  apiClient.get(`/api/game/status/${roomNumber.value}`).then((res) => {
    game.value = res.data;
    if (game.value.GameStatus) {
      gameStatus.value = game.value.GameStatus;
    }

    const isVisible = sessionStorage.getItem(
      `${roomNumber.value}${gameStatus.value}`
    );
    if (
      (gameStatus.value === "SUCCESS" || gameStatus.value === "FAIL") &&
      !isVisible
    ) {
      dialogVisible.value = true;
      sessionStorage.setItem(`${roomNumber.value}${gameStatus.value}`, "true");
    }
  });
};
// getGameStatus();
provide("getGameStatus", getGameStatus);
</script>
<style>
@import "./assets/css/color-dark.css";
</style>
