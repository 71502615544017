import { defineStore } from "pinia";

interface ObjectList {
  [key: string]: string[];
}

export const usePermissStore = defineStore("permiss", {
  state: () => {
    const keys = sessionStorage.getItem("ms_keys");
    return {
      key: keys ? JSON.parse(keys) : <string[]>[],
      defaultList: <ObjectList>{
        admin: [
          "1",
          "2",
          "3",
          "Statistics",
          "Project Manager",
          "Project Team Member",
          "IT Dept.",
          "Manufacturing Dept.",
          "President",
          "F Manager",
          "overview",
        ],
        user: ["1", "2", "3", "11", "13", "14", "15"],
        "Project Manager 1": ["Project Manager", 2],
        "Project Manager 2": ["Project Manager", 2],
        "Project Manager 3": ["Project Manager", 2],
        "Project Manager 4": ["Project Manager", 2],
        "Project Manager 5": ["Project Manager", 2],
        "Project Team Member": ["Project Team Member"],
        "IT Dept.": ["IT Dept."],
        "Manufacturing Dept.": ["Manufacturing Dept."],
        overview: ["overview"],
        "F Manager": ["F Manager"],
      },
    };
  },
  actions: {
    handleSet(val: string[]) {
      this.key = val;
    },
  },
});
