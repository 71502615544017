<template>
  <v-header />
  <v-sidebar />
  <div class="content-box" :class="{ 'content-collapse': sidebar.collapse }">
    <v-tags v-if="userType === 'admin'"></v-tags>
    <div class="content">
      <router-view v-slot="{ Component }">
        <transition name="move" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useSidebarStore } from "../store/sidebar";
import { useTagsStore } from "../store/tags";
import vHeader from "../components/header.vue";
import vSidebar from "../components/sidebar.vue";
import vTags from "../components/tags.vue";

const sidebar = useSidebarStore();
const tags = useTagsStore();
const userType = ref(sessionStorage.getItem("ms_usertype"));
</script>
